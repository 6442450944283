import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[customRowGroup]',
  standalone: true
})
export class CustomRowGroupDirective implements AfterViewInit, OnDestroy {
  private observer: any;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.applyLastRowStyle();

    // Observer pour détecter les changements dans la table (pagination, filtre, etc.)
    this.observer = new MutationObserver(() => {
      this.applyLastRowStyle();
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,  // Surveille les ajouts/suppressions d'éléments
      subtree: true,    // Inclut les enfants (tr, td, etc.)
    });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private applyLastRowStyle(): void {
    //setTimeout(() => {
      let table = this.el.nativeElement;
      let actualRow = table.querySelectorAll("td.last-group-column");

      actualRow.forEach((row: HTMLElement) => {
        this.renderer.removeClass(row, "last-group-column");

        if (row.parentElement) {
          row.parentElement.style.display = "none";
          row.parentElement.offsetHeight; // Force un reflow
          row.parentElement.style.display = "";
        }
      });

      let rows = table.querySelectorAll("td.regroup-column");

      if (rows.length > 0) {
        let lastCell = rows[rows.length - 1];

        if (lastCell && lastCell.parentElement) {
          this.renderer.addClass(lastCell, 'last-group-column');

          // Forcer le navigateur à redessiner l'élément
          lastCell.parentElement.style.display = "none";
          lastCell.parentElement.offsetHeight; // Force un reflow
          lastCell.parentElement.style.display = "";
        }
      }
    //}, 300);
  }
}
